import React, { useState, useEffect } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import swapContractABI from './swapContractABI.json';
import ERC20ABI from './ERC20ABI.json';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import Modals from "./components/modals";
import axios from 'axios';
import QRCode from 'react-qr-code';
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';


function App() {

  // 1. Get projectId
  const projectId = 'c678a959337b0c1969c2958dd820601a';

  // 2. Set chains
  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }
  const bsc = {
    chainId: 56,
    name: 'BNB Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org'
  }

  // 3. Create a metadata object
  const metadata = {
    name: 'My Website',
    description: 'My Website description',
    url: 'https://mywebsite.com', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }

  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    //rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 56 // used for the Coinbase SDK
  })

  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    chains: [bsc],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })

  // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

  const [swapContract, setSwapContract] = useState('0xB9106C68A586155Bc36276b47640Cd1A616c443F');
  const [isLoading, setIsLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [usdtContractBalance, setUsdtContractBalance] = useState(0);
  const [tokenContractBalance, setTokenContractBalance] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [title, setTitle] = useState(null);
  const [swapButtonText, setSwapButtonText] = useState("Swap");
  //const [swapFee, setSwapFee] = useState(0);
  const [minSwap, setMinSwap] = useState(0);
  const [adminWallet, setAdminWallet] = useState("0x");

  //const usdxSwapContract = '0xB9106C68A586155Bc36276b47640Cd1A616c443F';
  const usdtAddress ='0x55d398326f99059fF775485246999027B3197955';
  const swapFee = '300000000000000';

  const getContractBalances = async (swpContract) => {
    try {
      // Initialize ethers with BSC provider
      const provider = new JsonRpcProvider('https://bsc-dataseed.binance.org/');

      // Create a contract instance
      const contract = new Contract(swpContract, swapContractABI, provider);
      console.log(swpContract);

      // Fetch USDT balance
      const usdtContractBal = await contract.usdtBalance();
      setUsdtContractBalance(formatUnits(usdtContractBal, 18));

      // Fetch Token balance
      const tokenContractBal = await contract.usdxBalance();
      setTokenContractBalance(formatUnits(tokenContractBal, tokenDecimals));

    } catch (error) {
      console.error('Error getting balance:', error);
    }
  }

  const handleUsdtAmountChange = (event) => {
    setUsdtAmount(event.target.value);
    if(event.target.value > 0){
      setTokenAmount(event.target.value);
    }
  };

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
    if(event.target.value > 0){
      setUsdtAmount(event.target.value);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setSwapContract(response.data[0].value);
      setTokenContract(response.data[1].value);
      setTokenSymbol(response.data[2].value);
      setTokenDecimals(response.data[3].value);
      setTitle(response.data[4].value);
      //setSwapFee(response.data[5].value);
      setMinSwap(response.data[6].value);
      setAdminWallet(response.data[7].value);
      getContractBalances(response.data[0].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();

  }, []);

  const handelDirection = () => {
    setDirection(!direction);
    setTokenAmount(0);
    setUsdtAmount(0);
  }

  async function addToken() {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Currently only supports ERC20 tokens
          options: {
            address: tokenContract, // The token's contract address
            symbol: tokenSymbol,   // A string symbol of the token
            decimals: tokenDecimals,    // The number of decimals the token uses
            image: window.location.href + '/img/token-logo.png',  // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log('Token was added successfully!');
      } else {
        console.log('Token was not added.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const bscUrl = 'https://bscscan.com/token/' + tokenContract;
  const usdtUrl = 'https://bscscan.com/token/' + usdtAddress;


  const usdtWithdrawHandler = async() => {
    // alert(address);
    try{
      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = provider.getSigner();
        const contract = new Contract(swapContract, swapContractABI, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawTokens(adminWallet, usdtAddress);

        if(withdrawRes){
          alert("Congratulations, you will receive your USDT very soon");
        }else{
          alert("Something wrong, Only the admin wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the admin wallet who can withdraw.");
          console.log(err);
        }
  }

  const tokenWithdrawHandler = async() => {
    try{

      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = provider.getSigner();
        const contract = new Contract(swapContract, swapContractABI, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawUsdx(adminWallet);

        if(withdrawRes){
          alert("Congratulations, you will receive your tokens very soon");
        }else{
          alert("Something wrong, Only the admin wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the admin wallet who can withdraw.");
          console.log(err);
        }
  }

  const swapTokens = async() => {
    if (usdtAmount < minSwap && !direction) {
      alert("Enter at least " + minSwap + " USDT");
      return;
    }
    if (tokenAmount < minSwap && direction) {
      alert("Enter at least " + minSwap + " " + tokenSymbol);
      return;
    }
    if(chainId != 56){
      switchNetwork(56);
      return;
    }


      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(swapContract, swapContractABI, signer);
        const tokenContractApproval = new Contract(tokenContract, ERC20ABI, signer);
        const usdtContractApproval = new Contract(usdtAddress, ERC20ABI, signer);


        if(!direction){
          // Get the USDT balance
          const usdtBal = new Contract(usdtAddress, ERC20ABI, provider);
          const balance = await usdtBal.balanceOf(address);

          if(formatUnits(balance, 18) < parseInt(usdtAmount,10)){
            alert('Not enough USDT balance in your wallet');
            return;
          };
          if(tokenContractBalance < parseInt(usdtAmount,10)){
            alert('Not enough ' + tokenSymbol + ' tokens in the liquidity pool');
            return;
          };

          try {
            // Approve the swapContract to spend tokens
            console.log(usdtAmount);
            console.log(formatUnits(usdtAmount, 18));
            const approvalTx = await usdtContractApproval.approve(swapContract, parseUnits(usdtAmount, 18));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Swap USDT for Tokens
            const tx = await contract.swapUsdt(
              parseUnits(usdtAmount, 18),
              { value: swapFee}
            );
            console.log('Transaction sent:', tx);
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await tx.wait();
            console.log('Transaction confirmed:', tx);
            setSwapButtonText('Swap');
            setIsLoading(false);
            alert('Congratulations, Swap transaction confirmed');
          } catch (error) {
            setSwapButtonText('Swap');
            setIsLoading(false);
            console.error('Swap failed', error);
            alert('Swap failed');
          }
        }else{
          // Get the token balance
          const contractBal = new Contract(tokenContract, ERC20ABI, provider);
          const tokenBal = await contractBal.balanceOf(address);

          if(formatUnits(tokenBal, 18) < parseInt(tokenAmount, 10)){
            alert('Not enough ' + tokenSymbol + ' tokens balance in your wallet');
            return;
          };
          if(usdtContractBalance < parseInt(tokenAmount, 10)){
            alert('Not enough USDT in the liquidity pool');
            return;
          };

          try {
            // Approve the swapContract to spend tokens
            console.log('start approval');
            console.log(tokenAmount);
            console.log(parseUnits(tokenAmount, 18));
            const approvalTx = await tokenContractApproval.approve(swapContract, parseUnits(tokenAmount, 18));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Swap tokens for USDT
            const tx = await contract.swapUsdx(
              parseUnits(tokenAmount, 18),
              {value: swapFee}
            );
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            console.log('Transaction sent:', tx);
            await tx.wait();
            console.log('Transaction confirmed:', tx);
            setSwapButtonText('Swap');
            setIsLoading(false);
            alert('Congratulations, Swap transaction confirmed');
          } catch (error) {
            setSwapButtonText('Swap');
            console.error('Swap failed', error);
            setIsLoading(false);
            alert('Swap failed');
          }
        }

    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={() => open()} className="curr-btn btn-connect">Connect Wallet</button>
    )
  }

  const swapButton = () => {
    return (
      <button onClick={swapTokens} className="curr-btn btn-connect">{swapButtonText}</button>
    )
  }

  function isAdmin(){
    if(adminWallet === null || address === null){
      return false;
    }
    if(adminWallet.toLowerCase() === String(address).toLowerCase()){
      return true;
    }
    return false;
  }


  return (
    <>
      <nav className="navbar navbar-expand-sm flex-sm-nowrap flex-wrap">
        <div className="container-fluid">
          <button
            className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar5"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <span className="navbar-brand">
            <a href="/#">
              <img src="img/logo.png" height={50} width={200} alt="Add to MetaMask"/>
            </a>
          </span>

          <div className="navbar-collapse collapse" id="navbar5">

            <div className="d-flex w-100 justify-content-end">
              <div className="border-box-s d-flex flex-row align-item-center py-2">
                <div className="border-box-s d-flex">

                        <div className="textfirst">
                          <w3m-button />
                        </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </nav>


              <div className="card-st">
                <div className="card-d">

                    <main className="card-d-bg">
                      <div className="border-boxing flex-adjust-d space-s-between ma-color px-2 pb-1">
                        <div className="d-flex gap-2">
                          <div className="s-color pad-link"><h4>{title}</h4></div>
                        </div>

                        <div className="border-boxing flex-adjust-d width-fit-d justify-content-end">
                          <div className="position-relative">
                            <div className="dropdown">
                              <button
                                className="btn border-unset-d p-0"
                                type="button"
                                onClick={addToken}
                              >
                                <div className="border-boxing flex-adjust-d svg-pad pe-0">
                                  <img src="img/metamask.png" height={24} width={24} alt="Add to MetaMask"/>
                                </div>
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{display:'flex', flexDirection: `${direction ? 'column-reverse' : 'column'}`}}>
                        <div className="swap-card" id="from_token_select">
                          <div className="flex-wrap-d">
                            <div className="input-contain">
                              <div className="flex-ne">
                                <input
                                  className="input-swap-d input-d-swp"
                                  type="text"
                                  placeholder="0"
                                  value={direction? parseFloat(usdtAmount) : usdtAmount}
                                  onChange={handleUsdtAmountChange}
                                />
                                <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={usdtUrl} target='_blank' rel="noreferrer"><img src="img/usdt.png" height={32} width={32} alt="USDT Contract"/></a>
                                  </div>
                                  <span className="currency-text">USDT</span>
                                </div>
                              </div>
                              <div className="amount-div amt-d">
                                <div className="border-boxing flex-adjust-d space-s-between">
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="arrow-div" onClick={handelDirection}>
                          <button
                            // onClick={this.switchTokens}
                            id="swap_token_address"
                            className="arrow-swap"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#5D6785"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <polyline points="19 12 12 19 5 12"></polyline>
                            </svg>
                          </button>
                        </div>

                        <div className="swap-card" id="to_token_select">
                            <div className="flex-wrap-d">
                              <div className="input-contain">
                                <div className="flex-ne">
                                  <input
                                    className="input-swap-d input-d-swp"
                                    placeholder="0"
                                    value={direction? tokenAmount : parseFloat(tokenAmount)}
                                    onChange={handleTokenAmountChange}
                                  />
                                  <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={bscUrl} target='_blank' rel="noreferrer"><img src="img/token-logo.png" height={32} width={32} alt="Add to MetaMask"/></a>
                                    </div>
                                    <span className="currency-text">{tokenSymbol}</span>
                                  </div>
                                </div>
                                <div className="amount-div amt-d">
                                  <div className="border-boxing flex-adjust-d space-s-between">
                                    <div className=" "></div>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="input-div-s">
                        <div>

                        </div>

                        <div className="py-2">
                          <div className="accordion accordion-st" id="details2">
                            <div className="accordion-item accordion-itm">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed text-white accord-btn"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo2"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo2"
                                >
                                  Convert BNB to {tokenSymbol}
                                </button>
                              </h2>
                              <div
                                id="collapseTwo2"
                                className="accordion-collapse collapse"
                                data-bs-parent="#details2"
                              >
                                <div className="accordion-body pt-0">
                                  <div className="flex py-2 justify-space-between">
                                    <p className="text-white">Scan this QR code and send only BNB, you will receive {tokenSymbol} during seconds depend on current BNB price.</p>
                                    <div className="py-3" align="center" style={{ backgroundColor: 'white' }}>
                                      <QRCode
                                        title="Swap Contract"
                                        value={swapContract}
                                      />
                                    </div>
                                    <p className="text-white" align="center">{swapContract}</p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="py-2">
                          <div className="accordion accordion-st" id="details">
                            <div className="accordion-item accordion-itm">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed text-white accord-btn"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Pool Balance & Details
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                data-bs-parent="#details"
                              >
                                <div className="accordion-body pt-0">
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">USDT Balance:</p>
                                    <p className="m-0 text-grey">{Number(usdtContractBalance).toFixed(2)}</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">{tokenSymbol} Balance:</p>
                                    <p className="m-0 text-grey">{Number(tokenContractBalance).toFixed(2)}</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Rate:</p>
                                    <p className="m-0 text-grey">1 : 1</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Minmum Swap:</p>
                                    <p className="m-0 text-grey">${minSwap}</p>
                                  </div>
                                  <hr className="my-1 border-s-white" />

                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Fee</p>
                                    <p className="m-0 text-grey">{formatEther(swapFee)} BNB</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          {isConnected ? swapButton() : connectWalletButton()}
                          {isAdmin() &&
                            <div>
                              <br />
                              <button onClick={usdtWithdrawHandler} className="curr-btn btn-connect">Withdraw USDT</button>
                              <br />
                              <button onClick={tokenWithdrawHandler} className="curr-btn btn-connect">Withdraw {tokenSymbol}</button>
                            </div>
                          }
                        </div>
                      </div>
                    </main>

                </div>
              </div>

      <Modals />
    </>
  );
}

export default App;
